import { NgModule } from '@angular/core';
import { RouterModule, Routes } from '@angular/router';
import { CustomerComponent } from './customer.component';
import { DashboardComponent } from './dashboard/dashboard.component';
import { TemplatesComponent } from './templates/templates.component';
import { ConsentManagementComponent } from './consent-management/consent-management.component';
import { BusinessSettingsComponent } from './business-settings/business-settings.component';
import { DatepickerComponent } from '../datepicker/datepicker.component';
import { ProductComponent } from './product/product.component';
import { CampaignManagerComponent } from './campaign-manager/campaign-manager.component';
import { WhatsappPreviewComponent } from './whatsapp-preview/whatsapp-preview.component';
import { MyProfileComponent } from './my-profile/my-profile.component';
import { SupportComponent } from './support/support.component';
import { AnalyticsComponent } from './analytics/analytics.component';
import { ReportsComponent } from './reports/reports.component';
import { MediaDriveComponent } from './media-drive/media-drive.component';
import { AuthGuard } from '../services/auth.guard';
import { CampaignsetupComponent } from './campaign-manager/campaignsetup/campaignsetup.component';
import { CampaignViewComponent } from './campaign-manager/campaignview/campaignview.component';
import { CampaignListComponent } from './campaign-manager/campaignlist/campaignlist.component';
import { TemplatelistComponent } from './templates/templatelist/templatelist.component';
import { TemplatesetupComponent } from './templates/templatesetup/templatesetup.component';
import { PermissionsGuard } from '../services/permissions.guard';
import { MyprofileEditComponent } from './my-profile/myprofile-edit/myprofile-edit.component';
import { ReportCreateComponent } from './reports/report-create/report-create.component';
import { ViewReportsComponent } from './reports/view-reports/view-reports.component';
import { CreateTemplateComponent } from './reports/create-template/create-template.component';
import { SsoGuard } from '../services/sso.guard';

const routes: Routes = [
  {
    path: '',
    component: CustomerComponent,
    canActivate: [AuthGuard],
    children: [
      {
        path: ':id/product',
        component: ProductComponent,
        canActivate: [SsoGuard],
      },
      {
        path: ':id/dashboard',
        component: DashboardComponent,
        canActivate: [PermissionsGuard],
      },
      {
        path: ':id/analytics',
        component: AnalyticsComponent,
        canActivate: [PermissionsGuard],
      },
      {
        path: ':id/campaign-manager',
        component: CampaignManagerComponent,
        children: [
          { path: '', component: CampaignListComponent },
          { path: 'create', component: CampaignsetupComponent },
          { path: ':id/edit', component: CampaignsetupComponent },
          { path: ':id/view', component: CampaignViewComponent },
        ],
        canActivate: [PermissionsGuard],
      },
      {
        path: ':id/templates',
        component: TemplatesComponent,
        children: [
          { path: '', component: TemplatelistComponent },
          { path: 'create', component: TemplatesetupComponent },
          { path: ':id/edit', component: TemplatesetupComponent },
        ],
        canActivate: [PermissionsGuard],
      },
      {
        path: ':id/consent-management',
        component: ConsentManagementComponent,
        canActivate: [PermissionsGuard],
      },
      {
        path: ':id/reports',
        component: ReportsComponent,
        children: [
          { path: '', component: ViewReportsComponent },
          { path: 'create', component: ReportCreateComponent },
          { path: ':id/edit', component: ReportCreateComponent },
          { path: 'export/:name', component: ReportCreateComponent },
          { path: 'create-template', component: CreateTemplateComponent },
          { path: 'clone/:name', component: CreateTemplateComponent },
        ],
        canActivate: [PermissionsGuard],
      },
      {
        path: ':id/media',
        component: MediaDriveComponent,
        canActivate: [PermissionsGuard],
      },
      {
        path: ':id/support',
        component: SupportComponent,
        canActivate: [PermissionsGuard],
      },
      {
        path: ':id/business-settings',
        component: BusinessSettingsComponent,
        canActivate: [PermissionsGuard],
      },
      {
        path: ':id/datepicker',
        component: DatepickerComponent,
      },
      {
        path: ':id/whatsapp',
        component: WhatsappPreviewComponent,
      },
      {
        path: ':id/my-profile',
        component: MyProfileComponent,
        canActivate: [PermissionsGuard],
      },
      {
        path: ':id/my-profile/edit',
        component: MyprofileEditComponent,
        canActivate: [PermissionsGuard],
      },
      {
        path: ':id/my-profile/edit/pswd',
        component: MyprofileEditComponent,
        canActivate: [PermissionsGuard],
      },
    ],
  },
];

@NgModule({
  imports: [RouterModule.forChild(routes)],
  exports: [RouterModule],
})
export class CustomerRoutingModule {}
